<template>
    <!-- 校务管理 > 宿舍管理 > 宿舍管理 -->
    <school-manage-dormitory-manage-scroll-wrapper />
</template>

<script>
import SchoolManageDormitoryManageScrollWrapper from "@/components/scrollWrapper/SchoolManageDormitoryManage";
export default {
    name: "SchoolManageDormitoryManage",
    components: {
        SchoolManageDormitoryManageScrollWrapper
    }
}
</script>

<style scoped>

</style>
