<!-- 校园指挥中心/数据采集 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SchCenterData",
        components: {
            Building
        }
    }
</script>