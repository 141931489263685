<template>
    <!-- 协同办公 > 校园OA > 请假统计 -->
    <school-leave-record-analysis-scroll-wrapper></school-leave-record-analysis-scroll-wrapper>
</template>

<script>
import SchoolLeaveRecordAnalysisScrollWrapper from "@/components/scrollWrapper/SchoolLeaveRecordAnalysis";

export default {
    name: "CoworkingLeaveStatistics",
    components: {
        SchoolLeaveRecordAnalysisScrollWrapper,
    },
};
</script>

<style scoped>

</style>
