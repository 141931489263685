<template>
    <!-- 校务管理 > 宿舍管理 -->
    <KeepAliveRouterView />
</template>

<script>
export default {
    name: "SchoolManageDormitory"
}
</script>

<style scoped>

</style>
