<!-- 校园指挥中心 -->
<template>
    <div>
        <KeepAliveRouterView />
    </div>
</template>
<script>
    export default {
        name: "SchCenter"
    }
</script>
