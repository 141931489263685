<!-- 设置中心主容器 -->
<template>
    <div class="set-center">
        <set-center-header @back="handleBack"></set-center-header>
        <div class="set-center-content">
            <set-center-menus
                :setCenterRouter="setCenterRouter"
                :activeMenu="activeMenu"
                @menuClick="handlerMenuClick"
            ></set-center-menus>
            <div class="set-center-container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SetCenterHeader from '@/components/scrollWrapper/SetCenterMainContainer/Header.vue'
import SetCenterMenus from '@/components/scrollWrapper/SetCenterMainContainer/Menus.vue'

export default {
    name: 'SetCenterMainContainer',
    components: {
        SetCenterHeader,
        SetCenterMenus
    },
    data() {
        return {
            setCenterRouter: [],
        }
    },
    computed: {
        ...mapState({
            userRouters: (state) => state.userRouters,
        }),
        activeMenu() {
            return this.$route.name
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            let setCenterRouter = this.userRouters.find(e => e.name === 'SetCenter')
            console.log("[设置中心主容器][init]setCenterRouter:", setCenterRouter);
            if (setCenterRouter && setCenterRouter.children && setCenterRouter.children.length > 0) {
                this.setCenterRouter = setCenterRouter.children
                this.$router.push({
                    name: this.setCenterRouter[0].name
                })
            }
        },
        handleBack() {
            // window.close()
            this.$router.replace({
                path: "/"
            })
        },
        handlerMenuClick(menu) {
            this.$router.push({
                name: menu.name
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$header-height: 60px;

.set-center {
    height: 100%;
}

.set-center-header {
    height: $header-height;
}

.set-center-content {
    display: flex;
    flex-direction: row;
    height: calc(100% - #{$header-height});
    padding-top: 10px;
    box-sizing: border-box;

    .set-center-container {
        width: 100%;
        height: 100%;
        margin-left: 10px;
    }
}
</style>
