<template>
    <!-- 校务管理 > 宿舍管理 > 宿舍分配 -->
    <school-manage-dormitory-distribution-scroll-wrapper />
</template>

<script>
import SchoolManageDormitoryDistributionScrollWrapper from "@/components/scrollWrapper/SchoolManageDormitoryDistribution";
export default {
    name: "SchoolManageDormitoryDistribution",
    components: {
        SchoolManageDormitoryDistributionScrollWrapper
    }
}
</script>

<style scoped>

</style>
